import { bindable, inject }             from 'aurelia-framework';
import { I18N }                         from 'aurelia-i18n';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseListViewModel }            from 'base-list-view-model';
import { FilterFormSchema }             from 'modules/management/report-headers/filter-form-schema';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';
import { ReportCategoriesRepository }   from './services/categories-repository';
import { ReportHeadersFilesRepository } from './services/files-repository';
import { ReportHeadersRepository }      from './services/repository';

@inject(AppContainer, ReportHeadersRepository, ReportCategoriesRepository, ReportHeadersFilesRepository, I18N, Downloader, FilterFormSchema,
)

export class ListReportHeaders extends BaseListViewModel {

    reportCategory;
    listingId;

    @bindable headerTitle;
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param categoriesRepository
     * @param filesRepository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, categoriesRepository, filesRepository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository           = repository;
        this.categoriesRepository = categoriesRepository;
        this.filesRepository      = filesRepository;
        this.i18N                 = i18N;
        this.downloader           = downloader;
        this.filterFormSchema     = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {
        this.reportCategory = await this.fetchCategory(params.categoryId);

        return super.can([
            `management.report-headers.manage`,
            `management.report-headers.view`,
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.repository.setCategory(this.reportCategory.id);

        this.listingId            = `management-specifications-report-headers-${this.reportCategory.slug}-listing`;
        this.headerTitle          = this.reportCategory.name;
        this.newRecordRoute       = `management.report-headers.create`;
        this.newRecordRouteParams = { category: this.reportCategory };

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.report-headers.view', { category: this.reportCategory, id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can([`management.report-headers.manage`, `management.report-headers.view`]),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.report-headers.edit', { category: this.reportCategory, id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can([`management.report-headers.manage`, `management.report-headers.edit`]),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: () => this.appContainer.authenticatedUser.can([`management.report-headers.manage`, `management.report-headers.delete`]),
            },
            redirectToButton:   { route: 'management.report-categories', class: 'btn btn-success', icon: 'icon-home4' },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-download7',
                    action:  (row) => this.downloadFile(row.image_file),
                    visible: (row) => row.image_file !== null,
                    tooltip: 'form.button.download-image',
                },
            ],
            options:            [],
            selectable:         true,
            destroySelected:    true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'title',
                    name:  'report_header_translations.title',
                    title: 'form.field.title',
                },
                {
                    data:  'code',
                    name:  'report_headers.code',
                    title: 'form.field.code',
                },
                {
                    data:  'model',
                    name:  'report_headers.model',
                    title: 'form.field.model',
                },
                {
                    data:  'revision',
                    name:  'report_headers.revision',
                    title: 'form.field.revision',
                },
                {
                    data:            'date',
                    name:            'report_headers.date',
                    title:           'form.field.date',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:            'effective_date',
                    name:            'report_headers.effective_date',
                    title:           'form.field.effective_date',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:    'observations',
                    name:    'report_headers.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:       'image_file',
                    name:       'image_file',
                    title:      'form.field.image',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/report-headers/custom-listing-cells/file/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'report_headers.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Fetches data from server
     *
     * @param id
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchCategory(id) {
        return await this.categoriesRepository.find(id);
    }

    /**
     * Downloads the file
     *
     * @param file
     */
    downloadFile(file) {
        this.filesRepository.download(file.id)
            .then(result => {
                if (result) {
                    // remove the file extension from the file name
                    const fileName = file.display_name.split('.').slice(0, -1).join('.');
                    this.downloader.download(result, fileName, file.extension);
                }
            });
    }

}
