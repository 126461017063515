import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/management/report-headers/form-schema';
import { ReportCategoriesRepository }   from 'modules/management/report-headers/services/categories-repository';
import { ReportHeadersFilesRepository } from 'modules/management/report-headers/services/files-repository';
import { ReportHeadersRepository }      from 'modules/management/report-headers/services/repository';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';

@inject(AppContainer, ReportHeadersRepository, ReportCategoriesRepository, ReportHeadersFilesRepository, FormSchema, Downloader)
export class CreateReportHeader extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.report-headers.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param categoriesRepository
     * @param filesRepository
     * @param formSchema
     * @param downloader
     */
    constructor(appContainer, repository, categoriesRepository, filesRepository, formSchema, downloader) {
        super(appContainer);

        this.repository           = repository;
        this.categoriesRepository = categoriesRepository;
        this.filesRepository      = filesRepository;
        this.formSchema           = formSchema;
        this.downloader           = downloader;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {

        this.reportCategory = params.category;
        this.repository.setCategory(this.reportCategory.id);

        return super.can([
            `management.report-headers.manage`,
            `management.report-headers.view`,
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   'management.report-categories.index',
                    params: {
                        categoryId: this.reportCategory.id,
                    },
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
