import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/management/report-headers/form-schema';
import { ReportHeadersRepository }      from 'modules/management/report-headers/services/repository';
import { ReportCategoriesRepository }   from 'modules/management/report-headers/services/categories-repository';
import { ReportHeadersFilesRepository } from 'modules/management/report-headers/services/files-repository';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';

@inject(AppContainer, ReportHeadersRepository, ReportCategoriesRepository, ReportHeadersFilesRepository, FormSchema, Downloader)
export class EditReportHeader extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'management.report-headers.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param categoriesRepository
     * @param filesRepository
     * @param formSchema
     * @param downloader
     */
    constructor(appContainer, repository, categoriesRepository, filesRepository, formSchema, downloader) {
        super(appContainer);

        this.repository           = repository;
        this.categoriesRepository = categoriesRepository;
        this.filesRepository      = filesRepository;
        this.formSchema           = formSchema;
        this.downloader           = downloader;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {

        this.reportCategory = params.category;
        this.repository.setCategory(this.reportCategory.id);

        return super.can([
            `management.report-headers.manage`,
            `management.report-headers.view`,
        ]);
    }


    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this, params.categoryId);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name: 'management.report-categories.index',
                    params: {
                        categoryId: this.reportCategory.id
                    }
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
